import queryString from 'query-string';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { NewsroomFiltersProvider, PagerProvider } from '../../hooks';
import { NewsroomView } from '../2-organisms';

export type ParagraphViewsNewsroomProps = {
  className?: string;
};

export const ParagraphViewsNewsroom: React.FC<ParagraphViewsNewsroomProps> = ({
  className,
}) => {
  const { useLocation } = useFrameworkDependencies();
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search) as {
    organisation?: string;
    type?: string;
    event?: string;
  };
  const { organisation, type, event } = parsedSearch;
  return (
    <NewsroomFiltersProvider
      organisation={organisation}
      type={type}
      event={event}
    >
      <PagerProvider>
        <NewsroomView className={className} />
      </PagerProvider>
    </NewsroomFiltersProvider>
  );
};

export default ParagraphViewsNewsroom;
