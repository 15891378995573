import { ListingNewsroomQuery } from '@custom/schema';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useLocalization,
  useNewsEventsTerms,
  useNewsroomFilters,
  useNewsTypesTerms,
  usePager,
} from '../../hooks';
import { isTruthy } from '../../utils/isTruthy';
import { useOperation } from '../../utils/operation';
import { DOMAINS } from '../,,/../../constants';
import { Loader } from '../0-atoms';
import { NewsroomFilterForm } from '../1-molecules';
import { FadeUp } from '../1-molecules/FadeIn';
import { NewsroomTeaser } from './NewsroomTeaser';
import { RedesignedPager } from './RedesignedPager';

export type NewsroomViewProps = {
  itemsPerPage?: number;
  highlightPerPage?: number;
  enablePager?: boolean;
  showFilters?: boolean;
  className?: string;
};

export const NewsroomView: React.FC<NewsroomViewProps> = ({
  itemsPerPage = 15,
  highlightPerPage = 3,
  enablePager = true,
  showFilters = true,
  className,
}) => {
  const { t } = useTranslation();
  const { locale: currentLocale } = useLocalization();
  const newsroomTypes = useNewsTypesTerms(currentLocale);
  const newsroomEvents = useNewsEventsTerms(currentLocale);
  const { organisation, type, event } = useNewsroomFilters();

  const { currentPage } = usePager();

  const numPerPageLimit = itemsPerPage;

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }, [currentPage]);

  // Query to get the rest of the news.
  const { data, isLoading } = useOperation(ListingNewsroomQuery, {
    limit: numPerPageLimit || 15,
    offset: (enablePager ? currentPage - 1 : 0) * numPerPageLimit,
    newsType: type || '',
    newsEvent: event || '',
    organisation: organisation,
    highlightedCount: highlightPerPage,
    language: currentLocale,
  });

  // Results of news items.
  const newsItems = data?.listingNewsroom?.items || [];
  const total = data?.listingNewsroom?.total || 0;

  // Get all the domains that are not external.
  // We need to map the domains to the format that the FormTabs component expects.
  // We also need to sort the domains alphabetically.
  const domainIdMapped = Object.values(DOMAINS)
    .filter((domain) => domain.isExternal !== true)
    .map((domain) => ({
      id: domain.id,
      label: t(domain.title),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const newsroomTypesMapped = newsroomTypes
    ? newsroomTypes.map(({ id, title, weight }) => ({
        id: id.toString(),
        label: title || '',
        weight: weight || 0,
      }))
    : undefined;

  const newsroomEventsMapped = newsroomEvents
    ? newsroomEvents.map(({ id, title }) => ({
        id: id.toString(),
        label: title || '',
      }))
    : undefined;

  return (
    <div
      className={classNames(className, 'NewsroomView', {
        'Pane is-loading relative': isLoading,
      })}
    >
      {isLoading && <Loader />}
      {showFilters && (
        <NewsroomFilterForm
          organisations={domainIdMapped}
          types={newsroomTypesMapped}
          events={newsroomEventsMapped}
        />
      )}
      {newsItems && newsItems.length ? (
        <FadeUp yGap={20}>
          <div className="NewsroomView--grid">
            {newsItems.filter(isTruthy).map((newsItem, index) => (
              <NewsroomTeaser
                key={`newsroom-item-${index}`}
                {...newsItem}
                isHighlighted={
                  !!newsItem.sticky &&
                  index === 0 && // Only highlight (i.e. make big) the first item on the first page.
                  currentPage === 1 &&
                  newsItem.teaserImage !== null
                }
              />
            ))}
          </div>
        </FadeUp>
      ) : !isLoading ? (
        <p className="ArticleText">{t('newsroom.empty')}</p>
      ) : null}

      {enablePager && total > itemsPerPage ? (
        <RedesignedPager itemsCount={total} itemsPerPage={itemsPerPage} />
      ) : null}
    </div>
  );
};
