import { ParagraphStorytellingFragment } from '@custom/schema';
import React from 'react';

import { Image } from '../../components/0-atoms';
import ParagraphEmbeddedMedia from '../../components/0-atoms/ParagraphEmbeddedMedia';
import { Video } from '../../components/0-atoms/Video';
import { transformImageFragmentToProps } from './MediaImage';

export const transformParagraphStorytellingToProps = (
  paragraph: ParagraphStorytellingFragment,
) => {
  let media;
  if (paragraph?.media?.__typename === 'MediaImage') {
    const paragraphImage = paragraph?.media;
    media = paragraphImage ? (
      <Image
        {...transformImageFragmentToProps(paragraphImage, [
          'small',
          'medium',
          'large',
        ])}
        size="large"
        className="ArticleFigure--img"
        fit
        loading="lazy"
      />
    ) : undefined;
  } else if (paragraph?.media?.__typename === 'MediaVideo') {
    const paragraphVideo = paragraph?.media;
    media = paragraphVideo ? (
      <Video
        url={paragraphVideo.url}
        showControls={true}
        autoplay={false}
        loop={false}
        fullWidth={true}
        preload="auto"
      />
    ) : undefined;
  } else if (paragraph?.media?.__typename === 'MediaRemoteVideo') {
    const paragraphRemoteVideo = paragraph?.media;
    media = paragraphRemoteVideo ? (
      <ParagraphEmbeddedMedia
        url={paragraphRemoteVideo?.url}
        className={'EmbeddedMedia--storytelling'}
      />
    ) : undefined;
  }

  return {
    title: paragraph?.storyTitle,
    tag: paragraph?.headingLevel,
    text: paragraph?.textHtml,
    media: media,
    mediaAlignment: paragraph?.mediaAlignment,
    linkUrl: paragraph.link?.url,
    linkLabel: paragraph.link?.title,
  };
};
