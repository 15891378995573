import { ParagraphTeaserNewsroomFragment } from '@custom/schema';

export const transformParagraphViewsNewsroomTeaserToProps = (
  paragraph: ParagraphTeaserNewsroomFragment,
) => {
  const maxItems = parseInt(paragraph?.maxItems || '4');
  const events: string =
    paragraph?.events && paragraph?.events.length > 0
      ? paragraph?.events[0] || ''
      : '';

  return {
    title: paragraph.pTitle,
    domain: paragraph.domain,
    contentTypes: paragraph.contentTypes as string[],
    event: events,
    linkText: paragraph.linkText,
    itemsPerPage: maxItems < 4 ? 4 : maxItems,
    showAllParams: paragraph.urlParams,
  };
};
