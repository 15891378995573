import {
  ListingNewsroomQuery,
  ParagraphTeaserNewsroomUrlParams,
} from '@custom/schema';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { useDomain, useLocalization } from '../../hooks';
import { buildUrl } from '../../utils';
import { getTranslatedUrl } from '../../utils/getTranslatedUrl';
import { isTruthy } from '../../utils/isTruthy';
import { useOperation } from '../../utils/operation';
import { IconKey, IconSvg, Loader } from '../0-atoms';
import { FadeUp } from '../1-molecules/FadeIn';
import { DomainInfo, NewsroomTeaser } from './NewsroomTeaser';

export type NewsroomTeaserViewProps = {
  itemsPerPage?: number;
  highlightPerPage?: number;
  title?: string;
  linkText?: string;
  className?: string;
  organisation?: string;
  type?: string[];
  event?: string;
  showAllParams?: ParagraphTeaserNewsroomUrlParams;
};

export const NewsroomTeaserView: React.FC<NewsroomTeaserViewProps> = ({
  itemsPerPage = 4,
  highlightPerPage = 1,
  title = '',
  organisation = '',
  type = [],
  event = '',
  linkText = '',
  className,
  showAllParams,
}) => {
  const { t } = useTranslation();
  const { locale: currentLocale } = useLocalization();
  // Query to get the rest of the news.
  const { data, isLoading } = useOperation(ListingNewsroomQuery, {
    limit: itemsPerPage || 4,
    offset: 0,
    newsType: type || '',
    newsEvent: event || '',
    organisation: organisation,
    highlightedCount: highlightPerPage,
    language: currentLocale,
  });

  const settings = useStaticQuery(graphql`
    query SettingsNewsroomUrl {
      settingsNewsroom {
        newsroomLink {
          translations {
            langcode
            link {
              url
            }
          }
        }
      }
    }
  `);

  // Results of news items.
  const newsItems = data?.listingNewsroom?.items || [];
  const storiesNewsItems: typeof newsItems = [];
  const newsItemsFiltered: typeof newsItems = [];

  // Swiper config
  const swiperInstance = useRef<SwiperClass>();
  const [isPreviousActive, setIsPreviousActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(false);

  // If there are more than one news item, we need to extract the first one to be highlighted.
  let newsItemHighlighted: (typeof newsItems)[number] | null = null;

  if (newsItems && newsItems.length > 0) {
    newsItems.map((item) => {
      if (item?.teaserImage) {
        storiesNewsItems.push(item);
      }
    });
    // set the highlighted item to the first one found (if found) from above
    if (storiesNewsItems.length > 0) {
      newsItemHighlighted = storiesNewsItems[0];
    }

    // filter out highlighted item out of the newsItems if there is one found
    if (newsItemHighlighted && newsItemHighlighted.path) {
      const newsItemHighlightedPath = newsItemHighlighted.path;

      newsItems.map((item) => {
        if (item?.path !== newsItemHighlightedPath) {
          newsItemsFiltered.push(item);
        }
      });
    }
  }

  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Extract all the url params from the showAllParams.
  const urlsParams: { [k: string]: any } = {};
  if (showAllParams && Object.keys(showAllParams).length > 0) {
    for (const key in showAllParams) {
      const paramKey = key as keyof ParagraphTeaserNewsroomUrlParams;
      if (showAllParams[paramKey]) {
        const data = showAllParams[paramKey] ?? [];
        if (data.length === 1) {
          urlsParams[key] = data[0]?.uuid ?? '';
        }
      }
    }
  }

  // Get the newsroom url from the settings.
  // The url is translated, so we need to find the correct translation.
  const newsroomUrl = getTranslatedUrl({
    urlObject: settings.settingsNewsroom?.newsroomLink?.translations || [],
    langcode: currentLocale,
  }) as unknown as string;
  const newsroomUrlParts = newsroomUrl.split('/');
  const readMoreUrl = buildUrl(['/', ...newsroomUrlParts], urlsParams);

  const { getDomain } = useDomain();
  const newsroomTeaserDomain = getDomain(organisation ?? 'paraplegie_ch');
  const hasImages = !!(newsItemHighlighted && containerWidth < 767);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, [data]);

  return (
    <FadeUp yGap={20}>
      <div className={'NewsroomTeaserView--Container'} ref={containerRef}>
        <div
          className={classNames(className, 'NewsroomTeaserView', {
            'Pane is-loading relative': isLoading,
          })}
        >
          {isLoading && <Loader />}

          <div className={'NewsroomTeaserView--Header'}>
            <h1 className={'NewsroomTeaserView--Header--Title'}>
              {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
              {!title && (
                <>
                  {t('newsroom.teaser.headline')} <br />{' '}
                  {t('newsroom.teaser.subheadline')}
                </>
              )}
            </h1>
            <a
              className={'NewsroomTeaserView--Header--More'}
              href={readMoreUrl}
            >
              {' '}
              {t(linkText)}
              <IconSvg
                wrapClass={'NewsroomTeaserView--Header-Arrow'}
                icon={`ico-arrow-right` as IconKey}
                transition
              />
            </a>
            {hasImages || !newsItemHighlighted ? (
              <div className="NewsroomTeaserView--Header--slider-arrows-container">
                <a
                  className={classNames(
                    'NewsroomTeaserView--Header--slider-arrows NewsroomTeaserView--Header--slider-arrows-prev',
                    {
                      'is-disabled': !isPreviousActive,
                      'is-hidden': !isPreviousActive && !isNextActive,
                    },
                  )}
                  href=""
                  rel="prev"
                  onClick={(event) => {
                    event.preventDefault();
                    swiperInstance.current?.slidePrev();
                  }}
                >
                  <span className="visuallyhidden">{t('Previous')}</span>
                  <i className="ico ico-bolt-left" />
                </a>
                <a
                  className={classNames(
                    'NewsroomTeaserView--Header--slider-arrows NewsroomTeaserView--Header--slider-arrows-next',
                    {
                      'is-disabled': !isNextActive,
                      'is-hidden': !isPreviousActive && !isNextActive,
                    },
                  )}
                  href=""
                  rel="next"
                  onClick={(event) => {
                    event.preventDefault();
                    swiperInstance.current?.slideNext();
                  }}
                >
                  <span className="visuallyhidden">{t('Next')}</span>
                  <i className="ico ico-bolt-right" />
                </a>
              </div>
            ) : null}
          </div>

          <div
            className={classNames(
              newsItemHighlighted && containerWidth > 767
                ? 'NewsroomTeaserView--List'
                : 'NewsroomTeaserView--Slider',
            )}
          >
            {newsItemsFiltered &&
            newsItemsFiltered?.length > 0 &&
            newsItemHighlighted &&
            containerWidth > 767 ? (
              <>
                <div className="NewsroomTeaserView--HighlightedTeaser">
                  <NewsroomTeaser
                    key={`newsroom-item-highlighted`}
                    {...newsItemHighlighted}
                    isHighlighted
                  />
                </div>

                <div className="NewsroomTeaserView--Teaser">
                  {newsItemsFiltered.filter(isTruthy).map((newsItem, index) => (
                    <NewsroomTeaser
                      key={`newsroom-item-${index}`}
                      {...newsItem}
                      isHighlighted={false}
                    />
                  ))}
                  {/*// View more custom teaser */}
                  <a
                    href={readMoreUrl}
                    className={classNames('NewsTeaser viewMore')}
                  >
                    <DomainInfo
                      domain={newsroomTeaserDomain}
                      hideTitle={true}
                    />
                    <div className={'NewsTeaser--Content'}>
                      <h3 className={'NewsTeaser--Title'}>{t(linkText)}</h3>
                      <IconSvg
                        wrapClass={'NewsTeaser--Link'}
                        icon={`ico-bolt-right` as IconKey}
                        fill="#00417A"
                      />
                    </div>
                  </a>
                </div>
              </>
            ) : (newsItems && newsItems?.length && !newsItemHighlighted) ||
              (newsItems && newsItems?.length && hasImages) ? (
              <Swiper
                className="NewsroomTeaserView--Teaser--slider-list"
                wrapperTag="ul"
                breakpoints={{
                  320: {
                    slidesPerView: 1.1,
                  },
                  480: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
                onSwiper={(instance) => {
                  swiperInstance.current = instance;
                  setIsPreviousActive(false);
                  setIsNextActive(
                    instance.slides.length >
                      (typeof instance.params.slidesPerView === 'number'
                        ? instance.params.slidesPerView || 2
                        : 2),
                  );
                }}
                onSlideChange={(instance) => {
                  setIsPreviousActive(instance.realIndex > 0);
                  setIsNextActive(
                    instance.slides.length >
                      instance.realIndex +
                        (typeof instance.params.slidesPerView === 'number'
                          ? instance.params.slidesPerView || 2
                          : 2),
                  );
                }}
                updateOnWindowResize={typeof ResizeObserver !== undefined}
              >
                {newsItems.filter(isTruthy).map((newsItem, index) => (
                  <SwiperSlide
                    key={index}
                    tag="li"
                    className="RelatedArticles--teaser"
                  >
                    <NewsroomTeaser
                      {...newsItem}
                      isHighlighted={false}
                      hasImages={hasImages}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : !isLoading ? (
              <p className="ArticleText">{t('newsroom.empty')}</p>
            ) : null}
          </div>

          {newsItemHighlighted && containerWidth > 767 && (
            <IconSvg
              wrapClass="NewsroomTeaserView--ScrollIcon"
              icon={`ico-scroll-arrow` as IconKey}
              transition
            />
          )}

          <h1 className={'NewsroomTeaserView--Header--Title-Mobile'}>
            {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
            {!title && (
              <>
                {t('newsroom.teaser.headline')} <br />{' '}
                {t('newsroom.teaser.subheadline')}
              </>
            )}
          </h1>
        </div>
      </div>
    </FadeUp>
  );
};
