import React, { PropsWithChildren, useContext, useState } from 'react';

export type NewsroomContext = {
  organisation?: string;
  type?: string;
  event?: string;
  setOrganisation: (organisation: string) => void;
  setType: (type: string) => void;
  setEvent: (event: string) => void;
};
const NewsroomFiltersContext = React.createContext<NewsroomContext | undefined>(
  undefined,
);

export const NewsroomFiltersProvider: React.FC<
  PropsWithChildren<{
    organisation?: string;
    type?: string;
    event?: string;
  }>
> = ({
  organisation: initialOrganisation,
  type: initialType,
  event: initialEvent,
  children,
}) => {
  const [organisation, setOrganisation] = useState(initialOrganisation);
  const [type, setType] = useState(initialType);
  const [event, setEvent] = useState(initialEvent);

  return (
    <NewsroomFiltersContext.Provider
      value={{
        organisation,
        type,
        event,
        setOrganisation: (organisation: string) =>
          setOrganisation(organisation),
        setType: (type: string) => setType(type),
        setEvent: (event: string) => setEvent(event),
      }}
    >
      {children}
    </NewsroomFiltersContext.Provider>
  );
};

export const useNewsroomFilters = () => {
  const context = useContext(NewsroomFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useNewsroomFilters must be used within a NewsroomFiltersContext',
    );
  }
  return context;
};
