import classNames from 'classnames';
import { useReducedMotion } from 'framer-motion';
import { decode } from 'he';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { Video } from './Video';

export type ParagraphHomeSliderItemProps = {
  image?: string | ReactNode;
  mobileImage?: string | ReactNode;
  video?: string;
  videoPoster?: string;
  mobileVideo?: string;
  title?: string;
  description?: string;
  url?: string;
  target?: string;
  className?: string;
};

export const ParagraphHomeSliderItem: React.FC<
  ParagraphHomeSliderItemProps
> = ({
  image,
  mobileImage,
  video,
  videoPoster,
  title,
  description,
  url,
  target,
  className,
  mobileVideo,
}) => {
  const { t } = useTranslation();
  const { Link } = useFrameworkDependencies();
  const hasVideo = !!(video && video.length > 0);
  const reducedMotion = useReducedMotion();
  const hasImage = !!(
    image &&
    (typeof image === 'string' || typeof image === 'object')
  );
  const hasMobileImage = !!(
    mobileImage &&
    (typeof image === 'string' || typeof mobileImage === 'object')
  );

  // If we don't have a mobile image, use the image as a fallback.
  if (!hasMobileImage && hasImage) {
    mobileImage = image;
  }

  // If we don't have an image, use the mobile image as a fallback.
  if (!hasImage && hasMobileImage) {
    image = mobileImage;
  }

  return (
    <div className={classNames('HeaderSliderSlide withVideo', className)}>
      {mobileImage || mobileVideo ? (
        <div
          className={classNames(
            'HeaderSliderSlide--background mobile is-initialized',
            {
              HomeSlider: hasVideo,
            },
          )}
        >
          {mobileVideo ? (
            <Video
              url={mobileVideo}
              image={image}
              poster={videoPoster}
              imageTitle={title}
              videoClassName={'HeaderSliderSlide--background--video mobile'}
              imageWrapperClassName={
                'HeaderSliderSlide--background is-initialized HomeSlider--video mobile'
              }
              imageClassName={'HeaderSliderSlide--img HomeSlider--video--img'}
              autoplay={!reducedMotion}
              loop={true}
            />
          ) : typeof mobileImage === 'string' ? (
            <img
              src={mobileImage}
              className="HeaderSliderSlide--img HomeSlider--img"
              alt={title}
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
              loading="eager"
            />
          ) : (
            mobileImage
          )}
        </div>
      ) : null}
      {video ? (
        <Video
          url={video}
          image={image}
          poster={videoPoster}
          imageTitle={title}
          videoClassName={'HeaderSliderSlide--background--video'}
          imageWrapperClassName={
            'HeaderSliderSlide--background is-initialized HomeSlider--video'
          }
          imageClassName={'HeaderSliderSlide--img HomeSlider--video--img'}
          autoplay={!reducedMotion}
          loop={true}
        />
      ) : image ? (
        <div
          className={classNames(
            'HeaderSliderSlide--background is-initialized',
            {
              HomeSlider: hasVideo,
            },
          )}
        >
          {typeof image === 'string' ? (
            <img
              src={image}
              className="HeaderSliderSlide--img HomeSlider--img"
              alt={title}
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
              loading="eager"
            />
          ) : (
            image
          )}
        </div>
      ) : null}
      <div className="HeaderSliderSlide--content">
        {title && <h1 className="HeaderSliderSlide--title">{decode(title)}</h1>}
        {description && (
          <div
            className="HeaderSliderSlide--paragraph"
            dangerouslySetInnerHTML={{
              __html: decode(description.replace(/(^<p>|<\/p>$)/g, '')),
            }}
          />
        )}
        {url && (
          <Link className="HeaderSliderSlide--cta" to={url} target={target}>
            {t('Learn more')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ParagraphHomeSliderItem;
