import { Locale } from '../../i18n';
import { useFrameworkDependencies } from '../dependencies';
import { isTruthy } from '../utils/isTruthy';
import { useNewsEventsTermsQuery } from './useNewsEventsTermsQuery';

export const useNewsEventsTerms = (locale?: Locale) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const newsTerms = useNewsEventsTermsQuery();

  return (
    newsTerms.fetchAllTermNewsEvent
      ?.filter(isTruthy)
      .filter((term) => term.locale === (locale || currentLocale)) || []
  );
};
