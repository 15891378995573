import classNames from 'classnames';
import React from 'react';

import { FadeUp } from '../1-molecules/FadeIn';

export type ParagraphCustomHtmlProps = {
  html?: string;
  className?: string;
};

export const ParagraphCustomHtml: React.FC<ParagraphCustomHtmlProps> = ({
  html,
  className,
}) => {
  className = className || 'paragraph-custom-html';
  if (!html) {
    return null;
  }
  // ConvertFlow does not trigger its callback when React re-renders,
  // e.g. on language change, so we need to make sure to start it.
  if (typeof window !== 'undefined' && window?.convertflow) {
    // @ts-ignore
    convertflow.start();
  }
  return (
    <FadeUp yGap={20}>
      <div
        className={classNames('ArticleTitle', className)}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </FadeUp>
  );
};

export default ParagraphCustomHtml;
