import React, { useEffect } from 'react';

import { History } from '../../types';
import { HistoryNav } from '../0-atoms';
import { Content } from './Content';

export type HistoryViewProps = {
  historyData: History[];
  className?: string;
};

export const HistoryView: React.FC<HistoryViewProps> = ({
  historyData,
  className,
}) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const anchorNav = document.querySelector('.AnchorNav');
    if (!anchorNav) {
      return;
    }

    // overflow-x: hidden is set globally in the CSS.
    // This prevents sticky to work.
    const body = document.querySelector('.body');
    if (body) {
      body.setAttribute('style', 'overflow-x: unset;');
    }
    // We cannot use "position: fixed" for sticky
    // or use flexbox to get the expected document
    // flow, due to the current css implementation, so calculate
    // the height of the anchor nav and use it to adjust the margin of the
    // history content with offset. This does not apply to mobile
    // so listen to the resize event to adjust the margin.
    const setHistoryContentMargin = () => {
      const historyContent = document.querySelector('.HistoryContent');
      if (historyContent) {
        historyContent.setAttribute(
          'style',
          `margin-top: -${anchorNav.clientHeight - 20}px`,
        );
        historyContent.classList.remove('visuallyhidden');
      }
    };
    setHistoryContentMargin();
    if (anchorNav.checkVisibility()) {
      window.addEventListener('resize', setHistoryContentMargin);
    }
  }, []);
  return (
    <div className={className}>
      <HistoryNav
        years={historyData
          .map(({ title }) => (title ? parseInt(title) : undefined))
          .filter((i): i is number => Boolean(i))}
      />
      <div className="HistoryContent visuallyhidden">
        {historyData.map((history: History, index: number) => (
          <div
            key={`history-${index}`}
            data-history={history.title}
            id={history.title}
          >
            <div className="ArticleTitle">
              <h2 className="ArticleTitle--title h1">{history.title}</h2>
            </div>
            <Content content={history?.content} />
          </div>
        ))}
      </div>
    </div>
  );
};
