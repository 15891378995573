import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type HistoryNavProps = {
  years: Array<number>;
  className?: string;
};

export const HistoryNav: React.FC<HistoryNavProps> = ({ years, className }) => {
  const { t } = useTranslation();
  const [currentYear, setCurrentYear] = useState<number | null>(null);
  // Needed for the sticky header.
  const HEADER_OFFSET = 170;

  const scrollToYear = useCallback((year: number) => {
    const element = document.querySelector(`[data-history='${year}']`);
    if (!element) return;

    // First scroll into view.
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });

    // Then adjust for header offset.
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - HEADER_OFFSET;

    // Use smooth scroll to final position.
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });

    setCurrentYear(year);
  }, []);

  const handleScrollButtonClick = useCallback(
    (direction: 'up' | 'down') => {
      if (!currentYear) {
        // If no year is selected, start with the first one
        scrollToYear(years[0]);
        return;
      }

      const currentIndex = years.indexOf(currentYear);
      if (currentIndex === -1) return;

      // Calculate target index based on direction
      const targetIndex =
        direction === 'up'
          ? Math.max(0, currentIndex - 1)
          : Math.min(years.length - 1, currentIndex + 1);

      // Only scroll if we're not at the boundaries
      if (targetIndex !== currentIndex) {
        scrollToYear(years[targetIndex]);
      }
    },
    [years, currentYear, scrollToYear],
  );

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') {
      return;
    }

    const historyEntries = document.querySelectorAll('[data-history]');
    if (!historyEntries.length) {
      return;
    }

    historyEntries.forEach((target) => {
      const observer = new IntersectionObserver((entries) => {
        const intersectingEntries = entries.filter(
          (entry) => entry.isIntersecting,
        );

        if (intersectingEntries.length) {
          const entryId =
            intersectingEntries[0].target.getAttribute('data-history');
          if (entryId) {
            setCurrentYear(parseInt(entryId, 10));
          }
        }
      });
      observer.observe(target);
    });
  }, []);

  const isFirstYear = currentYear === years[0];
  const isLastYear = currentYear === years[years.length - 1];

  return (
    <div
      className={classNames(
        'AnchorNav no-scroll no-topscroll no-bottomscroll',
        className,
      )}
    >
      <div className="AnchorNav--inner">
        <button
          className={classNames(
            'AnchorNav--scrollBtn AnchorNav--scrollBtn-up',
            {
              'is-disabled': isFirstYear,
            },
          )}
          type="button"
          onClick={() => handleScrollButtonClick('up')}
        >
          <span className="visuallyhidden">{t('Previous years')}</span>
        </button>
        <div className="AnchorNav--scroll">
          <ul className="AnchorNav--years">
            {years.map((year) => (
              <li key={`year-${year}`}>
                <a
                  className={classNames('AnchorNav--link', {
                    'is-active': year === currentYear,
                  })}
                  data-history-nav={year}
                  href={`#${year}`}
                  onClick={(event) => {
                    event.preventDefault();
                    scrollToYear(year);
                  }}
                >
                  <span className="visuallyhidden">{t('Year')}</span>
                  {year}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <button
          className={classNames(
            'AnchorNav--scrollBtn AnchorNav--scrollBtn-down',
            {
              'is-disabled': isLastYear,
            },
          )}
          type="button"
          onClick={() => handleScrollButtonClick('down')}
        >
          <span className="visuallyhidden">{t('Next years')}</span>
        </button>
      </div>
    </div>
  );
};
