import { useLocation } from '@reach/router';
// import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useNewsroomFilters, usePager } from '../../hooks';
import { FormVerticalSelect } from '../0-atoms';
import { FormTabs } from '../0-atoms/FormTabs';

export type NewsroomFilterFormProps = {
  organisations?: {
    id: string;
    label: string;
  }[];
  types?: {
    id: string;
    label: string;
  }[];
  events?: {
    id: string;
    label: string;
  }[];
  className?: string;
};

export const NewsroomFilterForm: React.FC<NewsroomFilterFormProps> = ({
  organisations,
  types,
  events,
}) => {
  const { t } = useTranslation();
  const { navigate } = useFrameworkDependencies();
  const location = useLocation();
  const { organisation, setOrganisation, type, setType, event, setEvent } =
    useNewsroomFilters();

  const { setCurrentPage } = usePager();

  const resetPage = () => setCurrentPage(1);

  const updateUrl = (query?: {
    organisation?: string;
    type?: string;
    event?: string;
  }) =>
    navigate(
      `${location.pathname}?${queryString.stringify(
        {
          organisation,
          type,
          event,
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      )}`,
    );

  return (
    <form className="NewsroomFilters">
      <div className="NewsroomFilters--row-1">
        {types && (
          <FormTabs
            id="u1"
            defaultValue={type ? type : ''}
            name="type"
            options={sortBy(types, 'weight').map(({ id, label }) => ({
              value: id,
              label,
            }))}
            onClick={(type) => {
              setType(type);
              updateUrl({
                type,
              });
              resetPage();
            }}
          />
        )}

        {organisations && (
          <FormVerticalSelect
            id="u2"
            name="organisation"
            className="NewsroomFilters--Organization"
            defaultValue={organisation || ''}
            emptyOption={{
              value: '',
              label: t('Organisation'),
            }}
            options={sortBy(organisations, 'label').map(({ id, label }) => ({
              value: id,
              label,
            }))}
            onChange={(event) => {
              setOrganisation(event.target.value);
              updateUrl({
                organisation: event.target.value,
              });
              resetPage();
            }}
          />
        )}
      </div>

      {events && (
        <div className="NewsroomFilters--Events">
          {events.map(({ id, label }) => (
            <div key={id} className={'NewsroomFilters--Events--Event'}>
              <label className="checkbox-toggle inline">
                <input
                  className="checkbox-toggle--input"
                  id="input"
                  type="checkbox"
                  name="event"
                  checked={event === id}
                  onChange={(event) => {
                    setEvent(event.target.checked ? id : '');
                    updateUrl({
                      event: event.target.checked ? id : '',
                    });
                    resetPage();
                  }}
                />
                <span className="checkbox-toggle--ui"></span>
                <span className="checkbox-toggle--label">{label}</span>
              </label>
            </div>
          ))}
        </div>
      )}
    </form>
  );
};
